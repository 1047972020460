import $SingleSignOn from '@/services/SingleSignOn'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { FaArrowRight } from 'react-icons/fa'

const Platform: React.FC<{ id: number, name: string }> = ({ id, name }) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const $redirect = useMutation({
    mutationFn: $SingleSignOn.platform,
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: ({ redirectUrl }) => {
      window.location.href = redirectUrl
    },
  })

  return (
    <button className="btn" onClick={() => $redirect.mutate(id)} disabled={isLoading}>
      {name}
      <span>
        {isLoading ? <Spinner animation="border" size="sm" /> : <FaArrowRight />}
      </span>
    </button>
  )
}

export default Platform
