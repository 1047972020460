import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Container } from './Platforms.styles'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'
import Platform from './Platform/Platform'

const Platforms: React.FC<{ widget: IWidget }> = ({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  return (
    <Container>
      {$widget.data?.map(platform => (
        <Platform {...platform} key={`platform-access-${platform.id}`} />
      ))}
    </Container>
  )
}

export default Platforms
